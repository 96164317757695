import React from 'react'
import { Link, graphql } from 'gatsby'
import {
  Container,
  Row,
  Col,
} from 'reactstrap'
import Header from '../components/pages/common/header'
import Layout from '../components/layout'
import Page from '../components/page'
import Helmet from 'react-helmet'

const VolunteerPage = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>Volunteer | Whickham Thorns Outdoor Activity Centre</title>
      </Helmet>
      <Header
        headerImage={data.headerImage}
        title="Volunteer with Us"
        height="20rem"
      />
      <Page>
        <Container>
            <p>Whickham Thorns Outdoor Activity Centre requires a lot of work in a huge range of areas. Here are just a few of the possible roles:</p>
            <ul>
                <li><strong>Admin & Finance</strong> – paperwork, bookings, procedures and money matters.</li>
                <li><strong>Maintenance & Repairs</strong> – from repairing activity equipment to maintaining gas, electrical systems</li>
                <li><strong>Activity Instructors</strong> – climbing, snow tubing, bushcraft, scout badges, possibilities are endless</li>
                <li><strong>Management & Leadership</strong> – with a management group and team leaders to pull it all together.</li>
                <li><strong>Grounds Maintenance</strong> – taking care of our green and pleasant land</li>
                <li><strong>Website & Marketing</strong> – web design, social media and marketing gurus</li>
                <li><strong>Development & Planning</strong> – the future and our dreams</li>
            </ul>

            <p>And many many more&hellip;</p>

            <p>If you're interested in volunteering, please <Link to="contact">get in touch</Link>.</p>
        </Container>
      </Page>
    </Layout>
  )
}

export const query = graphql`
  query VolunteerQuery {
    headerImage: file(relativePath: { regex: "/volunteer/" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 70)
      }
    }
  }
`

export default VolunteerPage
